<template>
  <v-card
    :loading="loading"
    height="100%"
  >
    <v-card-title>
      <a
        target="_blank"
        :href="`https://twitter.com/${username}`"
        class="mr-2"
      >{{ username }}</a> current subscriptions
    </v-card-title>
    <v-card-text>
      <v-list v-if="users.length">
        <v-list-item
          v-for="item in users"
          :key="item"
        >
          <v-chip
            role="button"
            :href="`https://twitter.com/${item}`"
          >
            {{ item }}
            <v-icon
              small
              class="ml-1"
              color="primary"
            >
              {{ icons.mdiOpenInNew }}
            </v-icon>
          </v-chip>
          <v-tooltip
            class="mr-2"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                :to="{
                  name: 'twitterPopularSubscribers',
                  query: {search:item}
                }"
                plain
                icon
                v-on="on"
              >
                <v-icon>{{ icons.mdiTooltipAccount }}</v-icon>
              </v-btn>
            </template>
            <span>User subscriptions info</span>
          </v-tooltip>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import TwitterApi from '@core/api/twitter'
import {
  mdiOpenInNew,
  mdiTooltipAccount,
} from '@mdi/js'

export default {
  data() {
    return {
      users: [],
      loading: false,
      username: '',
      icons: {
        mdiOpenInNew,
        mdiTooltipAccount,
      },
    }
  },
  mounted() {
    this.getSubscriptions(this.$route.query.user_id)
    this.username = this.$route.query.name
  },
  methods: {
    async getSubscriptions(id) {
      this.loading = true
      const { data } = await TwitterApi.TrackableUsers.getCurrentSubscriptions(id)
      this.loading = false
      this.users = data.items
    },
  },
}
</script>
